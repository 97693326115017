<template lang="pug">
  .page.options-page
    actions-line
      template(v-slot:center)
        locks(v-if="lockId && locks && version" ref="locks" :check-url="`${request}/check`" :lock-id="lockId" :locks="locks" :version="version")
      template(v-slot:right)
        a.item(v-if="frontUrl" :href="frontUrl" target="_blank")
          el-button(
            v-loading="loading"
            type="primary"
            icon="el-icon-view"
          ) Смотреть на сайте
        template(v-if="hasUpdatePermissions")
          el-button.item(
            v-if="frontUrl"
            v-loading="loading"
            type="success"
            icon="el-icon-time"
            :disabled="!isChanged"
            @click="openPreview"
          ) Предпросмотр
          el-button.item(
            v-loading="loading"
            type="success"
            icon="el-icon-top"
            :disabled="!isChanged"
            @click="submitForm"
          ) {{ loading && !dataLoading ? 'Сохранение' : 'Сохранить' }}
        template(v-else)
          el-tooltip.item(effect="light" content="У вас нет прав для сохранения данной страницы")
            el-button(
              v-loading="loading"
              icon="el-icon-top"
            ) Сохранить
    el-form.page__container.options-page__container(ref="postForm")
      .page__body(v-show="!dataLoading")
        tab-fields(:fields="fields" v-model="postForm")

    preview-dialog(ref="previewDialog" :link="frontUrl")

    back-to-top
</template>

<script>
import ActionsLine from '@/components/PagePartials/ActionsLine'
import PreviewDialog from '@/components/PagePartials/PreviewDialog'
import TabFields from '@/components/fields/TabFields'
import { notifyRequest, notifyErrorRequest } from '@/utils/api'
import SaveRoutes from '@/views/Templates/SaveRoutes'
import BackToTop from '@/components/BackToTop'
import Locks from '@/components/Locks'

export default {
  name: 'OptionsPage',
  extends: [SaveRoutes],
  components: {
    ActionsLine,
    PreviewDialog,
    TabFields,
    BackToTop,
    Locks,
  },
  data() {
    const validateRequire = (rule, value, callback) => {
      if (value === '') {
        this.$message({
          message: rule.field + ' is required',
          type: 'error',
        })
        callback(new Error(rule.field + ' is required'))
      } else {
        callback()
      }
    }
    return {
      postForm: null,
      fields: null,
      loading: true,
      dataLoading: true,
      tempRoute: {},
      formCache: '',
      frontUrl: null,

      lockId: null,
      locks: null,
      versions: null,
      skipVersionCheck: false,
    }
  },
  computed: {
    model() {
      return this.$route.meta.model
    },
    hasUpdatePermissions() {
      return this.$store.getters.permissions.includes(`update ${this.model.replace(/-/g, '_')}`)
    },
    isChanged() {
      return this.formCache !== JSON.stringify(this.postForm)
    },
    request() {
      return this.$route.meta.request
    },
    lang() {
      return this.$store.getters.language
    },
  },
  async created() {
    const fetchedData = await this.fetchData()
    this.fields = fetchedData.fields
    this.$store.commit('page/SET_FIELDS', this.fields)
    this.$store.commit('page/SET_PAGE', this.request.split('/')[this.request.split('/').length - 1].replace(/-/g, '_'))
    this.lockId = fetchedData.lockId
    this.version = fetchedData.version || { version: 0 }
    this.locks = fetchedData.locks
    this.postForm = Array.isArray(fetchedData.values) ? {} : fetchedData.values
    this.frontUrl = fetchedData.frontUrl
    this.$store.commit('page/SET_VIEW_URL', this.frontUrl)
    this.loading = false
    this.dataLoading = false
    await this.$nextTick()
    this.formCache = JSON.stringify(this.postForm)
  },
  mounted() {
  },
  watch: {
    postForm: {
      deep: true,
      handler() {
        this.$store.commit('page/SET_DATA', this.postForm)
      },
    },
    '$store.state.page.data': {
      deep: true,
      handler() {
        this.postForm = this.$store.state.page.data
      },
    },
  },
  methods: {
    async openPreview() {
      if (this.$refs.previewDialog.isLoading()) {
        return
      }
      this.$refs.previewDialog.showLoading()
      const data = JSON.parse(JSON.stringify(this.postForm))
      const responseData = await notifyErrorRequest({
        url: `${this.request}/preview`,
        method: 'put',
        data: data,
      }, 'Ошибка при проверке данных')
      if (responseData && responseData.data && responseData.data.version) {
        this.$refs.previewDialog.open(responseData.data.version)
      } else {
        this.$refs.previewDialog.stopLoading()
        this.$refs.previewDialog.hide()
      }
      /*
      this.$confirm('Данный функционал находится в разработке', 'Предпросмотр несохраненной страницы', {
        confirmButtonText: 'ОК'
        // type: 'warning'
      })
      */
    },
    async fetchData() {
      const response = await notifyErrorRequest({
        url: `${this.request}`,
        method: 'get',
        params: {},
      })
      return response
    },
    async storeData(data) {
      this.loading = true
      const responseData = await notifyRequest({
        url: `${this.request}`,
        method: 'put',
        data: data,
      }, 'Все поля успешно сохранены')
      if (responseData.errorCode && responseData.errorCode === 1) {
        this.$confirm('После того, как вы открыли данную страницу, в нее были внесены и сохранены изменения другими пользователями или вами в другой вкладке.' +
          ' Если вы нажмете "Да", вы перезапишите все эти изменения текущим состоянием данной страницы.', 'Перезаписать изменения?', {
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
          // type: 'warning'
          }).then(() => {
            this.storeData({ ...this.postForm, version: 'ignore' })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: 'Сохранение отменено',
            })
            this.loading = false
          })
        return
      }
      if (responseData && !responseData.errors && !responseData.error) {
        this.formCache = JSON.stringify(this.postForm)
        this.version = responseData.data.version
      }
      this.loading = false
    },
    submitForm() {
      this.storeData({ ...this.postForm, version: this.version.version })
    },
  },
}
</script>
<style rel="stylesheet/scss" lang="sass">
  @import "@/assets/sass/mixin.scss"
</style>
